<template>
  <div>
    <b-overlay
        :show="!pageLoaded"
    >
  <b-card
      class="practice-edit-wrapper"
  >
    <p><strong>SUBCONTRACTOR/AGENT PROFILE:</strong> This is where details are entered for each subcontractor or ‘agent’ used by the Practice whose work is ‘Relevant to compliance with the 2017 Regulations; or is otherwise capable of contributing to the identification and mitigation of the risks of MLTF to which the business is subject, or to the prevention or detection of MLTF in relation to the business.’</p>

    <p>Note: There is a separate form staff who are employees of the Practice</p>


    <!--form -->
    <b-form class="mt-2">
      <b-row>
        <b-col md="12">
          <h3>General Information</h3>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="First Name"
              label-for="contractor-first-name"
              class="mb-2"
          >
            <b-form-input
                id="contractor-first-name"
                v-model="contractor.firstName"
                @change="saveContractorProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
              label="Last Name"
              label-for="contractor-last-name"
              class="mb-2"
          >
            <b-form-input
                id="contractor-last-name"
                v-model="contractor.lastName"
                @change="saveContractorProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label="If the contractor is provided through a service-providing business, enter that business name here"
              label-for="service-providing-business"
              class="mb-2"
          >
            <b-form-input
                id="service-providing-business"
                v-model="contractor.serviceProvidingBusiness"
                @change="saveContractorProgress"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <label>Roles: select all that apply</label>
          <b-form-group>
            <b-row>
              <b-col md="6">
                <b-form-checkbox
                    v-model="contractor.roles"
                    id="bookkeeper"
                    name="contractor_roles"
                    value="Bookkeeper"
                    @change="saveContractorProgress"
                >
                  Bookkeeper
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="contractor.roles"
                    id="accountant"
                    name="contractor_roles"
                    value="Accountant"
                    @change="saveContractorProgress"
                >
                  Accountant
                </b-form-checkbox>
              </b-col>
              <b-col md="6">
                <b-form-checkbox
                    v-model="contractor.roles"
                    id="account-manager"
                    name="contractor_roles"
                    value="Account Manager"
                    @change="saveContractorProgress"
                >
                  Account Manager
                </b-form-checkbox>
                <b-form-checkbox
                    v-model="contractor.roles"
                    id="other"
                    name="contractor_roles"
                    value="Other"
                    @change="saveContractorProgress"
                >
                  Other
                </b-form-checkbox>
              </b-col>
            </b-row>
          </b-form-group>

        </b-col>
      </b-row>

      <risk-question-block :system-form-id="4" :form="form" :answers="contractor.answers"
                           v-on:updateProgressReturn="updateProgressListener"
                           v-on:updateRiskReturn="updateRiskListener" v-on:getAnswersReturn="getAnswersListener"
                           v-on:updateFilesReturn="updateFilesListener"></risk-question-block>

      <b-row>
        <b-col
            cols="8"
            class="mt-50"
        >
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="saveContractor(false)"
          >
            <span v-if="!savingContractor">Save Changes</span>
            <span v-if="savingContractor"><b-spinner small/> Saving...</span>
          </b-button>
          <b-button
              :disabled="!canComplete || completed"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :variant="!completed ? 'success' : 'flat-success'"
              class="mr-1"
              @click="saveContractor(true)"
          >
            <span class="align-middle" v-if="!completed && !savingContractor">Complete</span>
            <span class="align-middle" v-if="completed &&  !savingContractor"><feather-icon icon="CheckIcon"/> Completed</span>
            <span v-if="savingContractor"><b-spinner small/> Saving...</span>
          </b-button>
        </b-col>
        <b-col
            cols="4"
            class="mt-50 text-right"
        >
          <sup>Last updated: {{ contractor.lastUpdated }}</sup>
        </b-col>
      </b-row>
    </b-form>
    <!--form-->
  </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroupPrepend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.gb'
import riskService from "@core/services/risk/useRisk";
import contractorService from "@core/services/contractor/useContractor";
import RiskQuestionBlock from "../../blocks/RiskQuestionBlock";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BSpinner,
    BOverlay,
    RiskQuestionBlock,
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mounted() {
    if (this.$route.params.id) {
      this.contractor.id = this.$route.params.id;
    }
  },
  data() {
    return {
      contractor: {
        id: 0,
        practiceId: 0,
        firstName: "",
        lastName: "",
        serviceProvidingBusiness: "",
        roles: [],
        lastUpdated: "",
        answers: []
      },
      form: {},
      riskQuestions: [],
      contractorRisk: 0,
      canComplete: false,
      completed: false,
      savingContractor: false,
      pageLoaded: false
    }
  },
  methods: {
    getAnswersListener(value) {
      this.riskQuestions = value;
      this.getContractor();
    },
    getContractor() {
      if (this.$route.params.id) {
        contractorService.getContractor({
          id: this.$route.params.id
        }).then(response => {
          this.contractor = response.data;
          this.canComplete = this.contractor.isComplete;
          this.completed = this.contractor.completed;
          this.contractorRisk = this.contractor.risk;
          let that = this;

          if (this.contractor.answers.length > 0) {
            this.contractor.answers.forEach(function (answer) {
              that.$set(that.form, answer.questionLabel, answer)
            });
          }

          this.recalculateRisk();
          this.pageLoaded = true;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }else{
        this.pageLoaded = true;
      }
    },
    saveContractorProgress() {
      let userData = JSON.parse(localStorage.getItem('userData'));

      this.contractor.practiceId = userData.practiceId;

      if (this.contractor.id) {
        contractorService.updateContractor({
          contractor: this.contractor,
          complete: false,
          id: this.contractor.id
        }).then(response => {
          this.canComplete = response.data.complete;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        contractorService.createContractor({
          contractor: this.contractor,
          complete: false,
        }).then(response => {
          if (response.data.id) {
            this.contractor.id = response.data.id;
          }
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    saveContractor(complete) {
      this.savingContractor = true;
      let userData = JSON.parse(localStorage.getItem('userData'));

      this.contractor.practiceId = userData.practiceId;

      if (this.contractor.id) {
        contractorService.updateContractor({
          contractor: this.contractor,
          complete: complete,
          id: this.contractor.id
        }).then(response => {
          if (response.data.errors) {
            this.showErrorMessage(response.data.errors);
          } else {
            this.showSuccessMessage("You have successfully updated this contractor");
            window.location.href = '/subcontractor/' + this.contractor.id;

            if (complete && !this.completed) {
              this.completed = true;
            }
          }
          this.savingContractor = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      } else {
        contractorService.createContractor({
          contractor: this.contractor,
          complete: complete,
        }).then(response => {
          if (response.data.errors) {
            this.showErrorMessage("Something went wrong!");
          } else {
            this.showSuccessMessage("You have successfully created this contractor");
            this.contractor.id = response.data.id;

            window.location.href = '/subcontractor/' + this.contractor.id;
          }
          this.savingContractor = false;
        }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });
      }
    },
    getRiskQuestions() {
      riskService.getRiskQuestions({
        'system_form_id': 4
      }).then(response => {
        let that = this;
        this.riskQuestions = response.data.questions;
        this.riskQuestionCategories = response.data.categories;
        this.riskQuestions.forEach(function (question) {
          that.$set(that.form, question.questionLabel, {input: ""})
        });

        this.getContractor();
      }).catch(error => {
        if(error.response.status == 403){
          window.location = $themeConfig.app.suspendedAccountRedirect;
        }
      });

    },
    recalculateRisk() {
      this.contractorRisk = riskService.recalculateRisk(this.contractor.answers);
      this.checkIfComplete();
    },
    checkIfComplete() {
      this.complete = true;

      if (!this.contractor.firstName || !this.contractor.lastName || !this.contractor.roles)
        this.complete = false;

      if (this.contractor.answers.length !== this.riskQuestions.length)
        this.complete = false;
    },
    updateProgressListener(value) {
      this.contractor.answers = value;
      this.saveContractorProgress();
    },
    updateFilesListener(value) {
      this.files = value;
    },
    updateRiskListener(value) {
      this.contractorRisk = value;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
